import t from '@jetshop/intl';
import useLoginFields from '@jetshop/core/components/Auth/useLoginFields';
import useSignupForm from '@jetshop/core/components/Auth/useSignupForm';
import React from 'react';
import Input from '../../Forms/Input';
import { GlobalError } from '../../Forms/GlobalError';
import { SmallCaps } from '../../ui/Headings';
import { ButtonUI } from '../../ui/ButtonUI';

export default function LoginFields(props) {
  const { fields } = useLoginFields();
  const { globalError, isSubmitting } = useSignupForm();

  return (
    <section>
      <SmallCaps className="heading">{t('Email & Password')}</SmallCaps>
      {globalError && (
        <GlobalError style={{ marginBottom: '2em' }}>{globalError}</GlobalError>
      )}

      {fields.map(field => {
        return <Input {...field.inputProps} />;
      })}

      <ButtonUI
        data-testid="sign-up-button"
        type="submit"
        disabled={isSubmitting}
      >
        {isSubmitting ? t('Hold on a moment…') : t('Create Profile')}
      </ButtonUI>
    </section>
  );
}
